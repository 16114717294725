import React, { useState } from "react";

import NATI from '../../pages/NATI';


export default function Tabs() {


    // eslint-disable jsx-a11y/no-noninteractive-element-interactions
    return (
        <NATI />
    );
};
